<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="用户名称" prop="nickname">
                <el-input v-model="query.nickname" placeholder="请输入用户名称" clearable size="small"
                    @change="handleQuery" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone" >
                <el-input v-model="query.phone" placeholder="请输入用户名称" clearable size="small"
                    @change="handleQuery" />
            </el-form-item>
            <el-form-item label="注册日期" >
                <el-date-picker
                    v-model="dateList"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="selectDate"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <!-- <el-table-column label="会员编号" align="center" prop="id" /> -->
            <el-table-column label="会员昵称" align="center" prop="nickname" />
            <el-table-column label="商品图片" align="center" prop="skuInfo.skuDefaultImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center" prop="phone" />
            <el-table-column label="性别" align="center" prop="gender" >
                <template #default="scope"> 
                    <el-tag type="success" >{{ scope.row.gender == '0' ? '男' : (scope.row.gender == 1 ?'女':'保密') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="年龄" align="center" prop="age" >
                <template #default="scope">
                    {{ scope.row.age }} 岁
                </template>
            </el-table-column>
            <!-- <el-table-column label="余额" align="center" prop="money" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.money).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收益金额" align="center" prop="saleMoney" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.saleMoney).toFixed(2) }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="拥有站点" align="center" prop="" width="120">
                <template #default="scope">
                    <span v-if="scope.row.memberSiteList.length == 0">无</span>
                    <el-tag v-else v-for="(item, index) in scope.row.memberSiteList" :key="index"
                        type="success" style="margin-bottom: 5px;">
                        {{ item.name }}
                    </el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column label="所属站点" align="center" prop="sourceSiteName" >
                <template #default="scope">
                    <el-tag type="success">{{ scope.row.sourceSiteName }}</el-tag>
                </template>
            </el-table-column> -->

            <el-table-column label="直推分佣（%）" align="center" prop="sdirectSaleRatio" width="120">
                <template #default="scope">
                    <span style="color: red">{{ (scope.row.directSaleRatio).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="间推分佣（%）" align="center" prop="indirectSaleRatio" width="120">
                <template #default="scope">
                    <span style="color: red">{{ (scope.row.indirectSaleRatio).toFixed(2) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="注册时间" align="center" prop="createTime" />


            

            <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="handleUpdate(scope.row)" v-hasPermi="['pms:spuInfo:edit']">会员设置</el-button>
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="editLeader(scope.row)" v-hasPermi="['pms:spuInfo:edit']">绑定上级</el-button>

                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="handleQuery" />


        <!-- 余额详情 -->
        <el-dialog title="会员设置" :visible.sync="open" width="40%" center>
            <el-form :model="form" label-width="120px">
                <el-form-item label="直推分佣">
                    <el-input-number v-model="form.directSaleRatio" :precision="2" :min="0" :max="1 - form.indirectSaleRatio" :step="0.01"></el-input-number>
                    <span style="font-size: 12px; color: red; margin-left: 10px;">销售金额 X 分佣比例（%）</span>
                </el-form-item>
                <el-form-item label="间推分佣">
                    <el-input-number v-model="form.indirectSaleRatio" :precision="2" :min="0" :max="1 - form.directSaleRatio" :step="0.01"></el-input-number>
                    <span style="font-size: 12px; color: red; margin-left: 10px;">销售金额 X 分佣比例（%）</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editSubmit" size="mini">提 交</el-button>
                <el-button @click="open = false" size="mini">关 闭</el-button>
            </span>
        </el-dialog>

        <!-- 上级设置 -->
        <el-dialog title="上级设置" :visible.sync="viewOpen" width="70%" center>
            <el-form :model="leaderView" label-width="120px">
                <el-form-item label="绑定上级">
                    <el-select v-model="leaderView.leaderSaleId" placeholder="请选择"  filterable>
                        <el-option v-for="item in allMember"
                            :key="item.id"
                            :label="item.nickname + '（' + item.phone + '）'"
                            :value="item.id"
                            :disabled="item.id == leaderView.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <!-- 结构树 -->
            <div style="height: 70vh; border:1px solid #eee">
                <zm-tree-org ref="tree" :data="data" :disabled="disaled" :horizontal="horizontal" :collapsable="collapsable"
                    :label-style="style" :node-draggable="false" :default-expand-level="1" :only-one-node="onlyOneNode"
                    :clone-node-drag="cloneNodeDrag" 
                    :toolBar="toolBar" >
                    <!-- 自定义节点内容 -->
                    <template slot-scope="{node}">
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <img :src="node.id == '0'? logUrl : node.headImg" width="80px" height="80px" alt="image error">
                            <div style="margin-top: 10px;">{{ node.label }}</div>
                        </div>
                    </template>
                    <!-- 自定义展开按钮 -->
                    <template v-slot:expand="{ node }">
                        <div>{{ node.children.length }}</div>
                    </template>
                </zm-tree-org>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="clickStatus" type="primary" @click="editMemberLeader" size="mini">提 交</el-button>
                <el-button @click="viewOpen = false" size="mini">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'UmsList',
    data() {
        return {
            logUrl: "https://yuyitang.oss-cn-shenzhen.aliyuncs.com/ytfile/upload/2022/04/22/69217696-014a-45a3-bc93-9ee67d06048d.png",
            toolBar: {
                scale: false
            },
            // 列表
            list: [],
            // 选择日期
            dateList: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                nickname: '',
                phone: '',
                startDate: '',
                endDate: '',
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 弹窗数据
            form: {},
            // 弹窗控制
            open: false,
            // 视图数据
            leaderView: {},
            // 视图控制
            viewOpen: false,
            // 用户列表
            allMember: [],
            // 树结构
            data: {},
            horizontal: false,
            collapsable: false,
            onlyOneNode: true,
            cloneNodeDrag: false,
            expandAll: true,
            disaled: false,
            style: {
                background: '#fff',
                color: '#5e6d82'
            },
            // 防抖
            clickStatus: false,
        }
    },
    created() {
        this.getList()
        this.getAllMember()
        this.getTreeData()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.memberList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        /** 选择日期 */
        selectDate(e) {
            console.log(e);
            if (e != null && e.length > 0) {
                this.query.current = 1
                this.query.startDate = e[0]
                this.query.endDate = e[1]
                this.getList()
            }else {
                this.query.current = 1
                this.query.startDate = ''
                this.query.endDate = ''
                this.getList()
            }
        },
        // 重置
        reset() {
            this.query.current = 1
            this.query.nickname = '',
            this.query.phone = '',
            this.dateList = [],
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        // 会员设置
        handleUpdate(row) {
            this.form = row
            this.open = true
        },
        // 提交数据
        editSubmit() {
            api.editMember(this.form).then(res => {
                this.getList()
                this.open = false
                this.form = {}
                this.$message.success("设置成功")
            })
        },
        // 更改上级
        editLeader(data) {
            this.leaderView = data
            this.viewOpen = true
        },
        // 获取所有用户
        getAllMember() {
            api.memberList({
                current: 1,
                size: 10000
            }).then(res => {
                this.allMember = res.records
                this.allMember.unshift({
                    id: '0',
                    nickname: '小帅健康',
                    phone: '4000-667-169'
                })
            })
        },
        // 获取树结构
        getTreeData() {
            api.getTreeData().then(res => {
                this.data = res
            })
        },
        // 更改上级
        editMemberLeader() {
            this.clickStatus = true
            api.editMember(this.leaderView).then(res => {
                this.$message.success("绑定成功")
                this.getList()
                this.getTreeData()
                this.clickStatus = false
            })
        },
    }
}

</script>